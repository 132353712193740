import React, { useState, useEffect } from "react";
import { Form, Input, Button, Checkbox, Layout, Row, Col, Divider } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { authActions } from "@/actions";
import { Link, useLocation} from "react-router-dom";
const { Content, Footer } = Layout;


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LoginPage = () => {
  const dispatch = useDispatch();
  let query = useQuery();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  var userLogin = useSelector((state) => state.authReducer.user);
  var errorLogin = useSelector((state) => state.authReducer.error);

  console.log("errorLogin",errorLogin)
  useEffect(() => {
    const token = query.get("token");
    setLoading(false);
    dispatch(authActions.loginToken(token));

  }, []);

  return (
    <>
      <Layout className="layout">
        <Row>
          <Col>
            LOADING...
          </Col>
        </Row>

        <Footer style={{ textAlign: "center" }}>
         Tec ©2023
        </Footer>
      </Layout>
    </>
  );
};

export default LoginPage;
