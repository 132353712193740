import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import PageLoader from "@/components/PageLoader";

const Dashboard = lazy(() =>
    import(/*webpackChunkName:'DashboardPage'*/ "@/pages/Dashboard")
);
const TutorGroups = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/tutor/Groups")
);

const EditGroup = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/tutor/EditGroup")
);
const IndexTutor = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/tutor/Index")
);

const DaysTutor = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/tutor/Days")
);
const StudentStateView = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/tutor/StudentStateView")
);
const TutorCSV = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/tutor/CSV")
);


const StudentCompetenceView = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/tutor/StudentCompetenceView")
);
const Logout = lazy(() =>
    import(/*webpackChunkName:'LogoutPage'*/ "@/pages/auth/Logout")
);
const NotFound = lazy(() =>
    import(/*webpackChunkName:'NotFoundPage'*/ "@/pages/NotFound")
);
const CreateGroup = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/tutor/CreateGroup")
);


export default function TutorRouter() {
    const location = useLocation();
    return (
        <Suspense fallback={<PageLoader />}>
            <AnimatePresence exitBeforeEnter initial={false}>
                <Switch location={location} key={location.pathname}>
                    <PrivateRoute path="/" component={IndexTutor} exact />
                    <PrivateRoute path="/csv" component={TutorCSV} exact />
                    <PrivateRoute path="/mygroups" component={TutorGroups} exact />
                    <PrivateRoute path="/newgroup" component={CreateGroup} exact />
                    <PrivateRoute path="/days" component={DaysTutor} exact />
                    <PrivateRoute component={Logout} path="/logout" exact />
                    <PrivateRoute path="/group/:uuid" component={EditGroup} exact />

                    <PrivateRoute path="/student/:uuid/competences" component={StudentCompetenceView} exact />
                    <PrivateRoute path="/student/:uuid/state" component={StudentStateView} exact />

                    <Route
                        path="*"
                        component={NotFound}
                        render={() => <Redirect to="/" />}
                    />
                </Switch>
            </AnimatePresence>
        </Suspense>
    );
}
