export default {
    END_TRANSACTION: "END_TRANSACTION",
    INCREMENT_COUNTER: "INCREMENT_COUNTER",
    DECREMENT_COUNTER: "DECREMENT_COUNTER",
    INCREMENT_COUNTER_DOUBLE: "INCREMENT_COUNTER_DOUBLE",
    DECREMENT_COUNTER_DOUBLE: "DECREMENT_COUNTER_DOUBLE",
    API_ERRORED: "API_ERRORED",
    SET_COUNTER: "SET_COUNTER",
    START_REQUEST: "START_REQUEST",
    END_REQUEST: "END_REQUEST",
    LOGIN_USER: "LOGIN_USER",
    LOGOUT: "LOGOUT",
    REMOVEUSER: "REMOVEUSER",
    LOGIN_DATA: "LOGIN_DATA",
    LOGIN_ERROR: "LOGIN_ERROR",
    FORGOTPASSWORD: "FORGOTPASSWORD",
    FORGOTPASSWORD_DATA: "FORGOTPASSWORD_DATA",
    FORGOTPASSWORD_ERROR: "FORGOTPASSWORD_ERROR",
    RESETPASSWORD: "RESETPASSWORD",
    RESETPASSWORD_DATA: "RESETPASSWORD_DATA",
    RESETPASSWORD_ERROR: "RESETPASSWORD_ERROR",
    CLEAR_DATA_STEPS: "CLEAR_DATA_STEPS",
    GET_USERSTEPS: "GET_USERSTEPS",
    GET_USERSTEPS_DATA: "GET_USERSTEPS_DATA",
    GET_USERSTEPS_ERROR: "GET_USERSTEPS_ERROR",
    GET_DEGREES: "GET_DEGREES",
    GET_DEGREES_DATA: "GET_DEGREES_DATA",
    GET_DEGREES_ERROR: "GET_DEGREES_ERROR",
    GET_QUESTIONNAIRE_DEGREE: "GET_QUESTIONNAIRE_DEGREE",
    GET_QUESTIONNAIRE_DEGREE_DATA: "GET_QUESTIONNAIRE_DEGREE_DATA",
    GET_QUESTIONNAIRE_DEGREE_DATA_CLEAR: "GET_QUESTIONNAIRE_DEGREE_DATA_CLEAR",
    GET_QUESTIONNAIRE_DEGREE_ERROR: "GET_QUESTIONNAIRE_DEGREE_ERROR",
    GET_ASSESSMENTQUESTIONNAIRE_DEGREE: "GET_ASSESSMENTQUESTIONNAIRE_DEGREE",
    GET_ASSESSMENTQUESTIONNAIRE_DEGREE_DATA: "GET_ASSESSMENTQUESTIONNAIRE_DEGREE_DATA",
    GET_ASSESSMENTQUESTIONNAIRE_DEGREE_ERROR: "GET_ASSESSMENTQUESTIONNAIRE_DEGREE_ERROR",
    SAVE_useranswers: "SAVE_useranswers",
    SAVE_useranswers_ERROR: "SAVE_useranswers_ERROR",
    SAVE_useranswers_DATA: "SAVE_useranswersTA_DA",
    CLEAR_useranswers: "CLEAR_useranswers",
    GET_useranswers:"GET_useranswers",
    GET_useranswers_DATA:"GET_useranswers_DATA",
    GET_useranswers_ERROR:"GET_useranswers_ERROR",
    GET_useranswers_CLEAR:"GET_useranswers_CLEAR",
    SAVE_STEPUSER: "SAVE_STEPUSER",
    SAVE_STEPUSER_DATA: "SAVE_STEPUSER_DATA",
    SAVE_STEPUSER_ERROR: "SAVE_STEPUSER_ERROR",
    SAVE_STEPUSER_CLEAR: "SAVE_STEPUSER_CLEAR",

    GET_SCAPEBOOK:"GET_SCAPEBOOK",
    GET_SCAPEBOOK_DATA:"GET_SCAPEBOOK_DATA",
    GET_SCAPEBOOK_ERROR:"GET_SCAPEBOOK_ERROR",


    GET_COMPETENCES:"GET_COMPETENCES",
    GET_COMPETENCES_DATA:"GET_COMPETENCES_DATA",
    GET_COMPETENCES_ERROR:"GET_COMPETENCES_ERROR",

    GET_COMPETENCESSCAPEBOOK:"GET_COMPETENCESSCAPEBOOK",
    GET_COMPETENCESSCAPEBOOK_DATA:"GET_COMPETENCESSCAPEBOOK_DATA",
    GET_COMPETENCESSCAPEBOOK_ERROR:"GET_COMPETENCESSCAPEBOOK_ERROR",

    GET_CHALLENGE:"GET_CHALLENGE",
    GET_CHALLENGE_DATA:"GET_CHALLENGE_DATA",
    GET_CHALLENGE_ERROR:"GET_CHALLENGE_ERROR",
    GET_CHALLENGE_ASSESSMENT:"GET_CHALLENGE_ASSESSMENT",
    GET_CHALLENGE_ASSESSMENT_DATA:"GET_CHALLENGE_ASSESSMENT_DATA",
    GET_CHALLENGE_ASSESSMENT_ERROR:"GET_CHALLENGE_ASSESSMENT_ERROR",


    GET_COMPETENCE_ASSESSMENT:"GET_COMPETENCE_ASSESSMENT",
    GET_COMPETENCE_ASSESSMENT_DATA:"GET_COMPETENCE_ASSESSMENT_DATA",
    GET_COMPETENCE_ASSESSMENT_ERROR:"GET_COMPETENCE_ASSESSMENT_ERROR",

    GET_USERBADGES:"GET_USERBADGES",
    GET_USERBADGES_DATA:"GET_USERBADGES_DATA",
    GET_USERBADGES_ERROR:"GET_USERBADGES_ERROR",
    UPDATE_USERBADGES:"UPDATE_USERBADGES",
    CLEAR_UPDATE_USERBADGES:"CLEAR_UPDATE_USERBADGES",
    SAVE_USERBADGES:"SAVE_USERBADGES",
    SAVE_USERBADGES_DATA:"SAVE_USERBADGES_DATA",
    SAVE_USERBADGES_ERROR:"SAVE_USERBADGES_ERROR",
    SAVE_USERBADGES_CLEAR:"SAVE_USERBADGES_CLEAR",

    GET_USERREPORT:"GET_USERREPORT",
    GET_USERREPORT_DATA:"GET_USERREPORT_DATA",
    GET_USERREPORT_ERROR:"GET_USERREPORT_ERROR",

    LOGIN_USERSSO: "LOGIN_USERSSO"


}
