import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import PageLoader from "@/components/PageLoader";
import Login from "@/pages/auth/Login";

const Dashboard = lazy(() =>
  import(/*webpackChunkName:'DashboardPage'*/ "@/pages/Dashboard")
);
const Admin = lazy(() =>
  import(/*webpackChunkName:'AdminPage'*/ "@/pages/Admin")
);

const Init = lazy(() =>
    import( "@/pages/Init")
);

const Competences = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/step/one/Competences")
);

const CompetencesTwo = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/step/two/Competences")
);
const Dilemas = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/step/three/Dilemas")
);
const ShowDilema = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/step/three/ShowDilema")
);
const CompetencesThree = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/step/three/Competences")
);

const StudentCompetenceView = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/step/StudentCompetenceView")
);
const StartPotential = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/step/one/startChallenge")
);

const Cards = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/step/two/Card")
);

const Logout = lazy(() =>
  import(/*webpackChunkName:'LogoutPage'*/ "@/pages/auth/Logout")
);
const NotFound = lazy(() =>
  import(/*webpackChunkName:'NotFoundPage'*/ "@/pages/NotFound")
);

const CompetencesFour = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/step/four/Competences")
);
const QuestionnaireFour = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/step/four/Questionnaire")
);

const Profile = lazy(() => 
  import(/*webpackChunkName:'ProfilePage'*/ "@/pages/Profile")
);

const Shop = lazy(() => 
  import(/*webpackChunkName:'ProfilePage'*/ "@/pages/Shop")
);


export default function AppRouter() {
  const location = useLocation();
  return (
    <Suspense fallback={<PageLoader />}>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <PrivateRoute path="/" component={Init} exact />
          <PrivateRoute path="/profile" component={Profile} exact />
          <PrivateRoute path="/shop" component={Shop} exact />
          <PrivateRoute path="/competencest" component={StudentCompetenceView} exact />
          <PrivateRoute path="/one/competences" component={Competences} exact />
          <PrivateRoute path="/one/start/:code" component={StartPotential} exact />
         
          <PrivateRoute path="/two/competences" component={CompetencesTwo} exact />
          <PrivateRoute path="/two/cards/:code" component={Cards} exact />

          <PrivateRoute path="/three/competences" component={CompetencesThree} exact />
          <PrivateRoute path="/three/dilemas/:code" component={Dilemas} exact />
          <PrivateRoute path="/three/dilemas/:code/dilema/:id/:index" component={ShowDilema} exact />

          <PrivateRoute path="/four/competences" component={CompetencesFour} exact />
          <PrivateRoute path="/four/competences/:code" component={QuestionnaireFour} exact />
          <PrivateRoute component={Admin} path="/admin" exact />

          <PrivateRoute component={Logout} path="/logout" exact />

          <Route
            path="*"
            component={NotFound}
            render={() => <Redirect to="/notfound" />}
          />
        </Switch>
      </AnimatePresence>
    </Suspense>
  );
}
