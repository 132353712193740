import history from "@/utils/history";
import {takeEvery, call, put, select} from 'redux-saga/effects'
import actionTypes from "@/config/actionTypes";
import {request} from "@/request";

export default function* watcherQuestionnaires() {
    yield takeEvery(actionTypes.GET_USERREPORT, getReport)
    yield takeEvery(actionTypes.GET_QUESTIONNAIRE_DEGREE, getQuestionnaire)
    yield takeEvery(actionTypes.GET_ASSESSMENTQUESTIONNAIRE_DEGREE, getAssessmentQuestionnaire)
    yield takeEvery(actionTypes.SAVE_useranswers, saveAnswer)
    yield takeEvery(actionTypes.GET_useranswers, getAnswer)

}

function* getAnswer({payload}) {
    yield put({type: actionTypes.START_REQUEST})
    try {
        let apiEndpoint = `/user/assessment/answer/${payload.id}`
        const res = yield call(
            () =>
                new Promise((resolve, reject) => {
                    request.get(apiEndpoint, payload).then((response) => {
                        resolve(response)
                    })
                }),
        )

        if (res.success && res.result) {
            yield put({type: actionTypes.GET_useranswers_DATA, payload: res.result})
        } else {
            yield put({type: actionTypes.GET_useranswers_ERROR, payload: res})
        }
        yield put({type: actionTypes.GET_useranswers_CLEAR, payload: res})
    } catch (e) {
        yield put({type: actionTypes.GET_useranswers_ERROR, payload: e})
        yield put({type: actionTypes.GET_useranswers_CLEAR, payload: "s"})
    }
    yield put({type: actionTypes.END_REQUEST})
}

function* saveAnswer({payload}) {
    yield put({type: actionTypes.START_REQUEST})
    try {
        let apiEndpoint = `/user/assessment/answer`
        const res = yield call(
            () =>
                new Promise((resolve, reject) => {
                    request.post(apiEndpoint, payload).then((response) => {
                        resolve(response)
                    })
                }),
        )

        if (res.success && res.result) {
            yield put({type: actionTypes.SAVE_useranswers_DATA, payload: res.result})
        } else {
            yield put({type: actionTypes.SAVE_useranswers_ERROR, payload: res})
        }
        yield put({type: actionTypes.CLEAR_useranswers, payload: res})
    } catch (e) {
        yield put({type: actionTypes.SAVE_useranswers_ERROR, payload: e})
        yield put({type: actionTypes.CLEAR_useranswers, payload: "s"})
    }
    yield put({type: actionTypes.END_REQUEST})
}

function* getQuestionnaire({payload}) {
    yield put({type: actionTypes.START_REQUEST})
    try {
        let apiEndpoint = `/user/degree/${payload.code}/questionnaire`
        const res = yield call(
            () =>
                new Promise((resolve, reject) => {
                    request.get(apiEndpoint, null).then((response) => {
                        resolve(response)
                    })
                }),
        )

        if (res.success && res.result) {
            yield put({type: actionTypes.GET_QUESTIONNAIRE_DEGREE_DATA, payload: res.result})
            yield put({type: actionTypes.GET_QUESTIONNAIRE_DEGREE_DATA_CLEAR, payload: res.result})
        } else {
            yield put({type: actionTypes.GET_QUESTIONNAIRE_DEGREE_ERROR, payload: res})
        }
    } catch (e) {
        yield put({type: actionTypes.GET_QUESTIONNAIRE_DEGREE_ERROR, payload: e})
    }
    yield put({type: actionTypes.END_REQUEST})
}


function* getReport({payload}) {
    yield put({type: actionTypes.START_REQUEST})
    try {
        let apiEndpoint = `/user/questionnaire/${payload.code}/report`
        const res = yield call(
            () =>
                new Promise((resolve, reject) => {
                    request.get(apiEndpoint, null).then((response) => {
                        resolve(response)
                    })
                }),
        )

        if (res.success && res.result) {
            yield put({type: actionTypes.GET_USERREPORT_DATA, payload: res.result})
        } else {
            yield put({type: actionTypes.GET_USERREPORT_ERROR, payload: res})
        }
    } catch (e) {
        yield put({type: actionTypes.GET_USERREPORT_ERROR, payload: e})
    }
    yield put({type: actionTypes.END_REQUEST})
}

function* getAssessmentQuestionnaire({payload}) {
    yield put({type: actionTypes.START_REQUEST})

    try {
        let apiEndpoint = `/user/questionnaire/${payload.uuid}/assessments`
        const res = yield call(
            () =>
                new Promise((resolve, reject) => {
                    request.get(apiEndpoint, null).then((response) => {
                        resolve(response)
                    })
                }),
        )

        if (res.success && res.result) {
            yield put({type: actionTypes.GET_ASSESSMENTQUESTIONNAIRE_DEGREE_DATA, payload: res.result})
        } else {
            yield put({type: actionTypes.GET_ASSESSMENTQUESTIONNAIRE_DEGREE_ERROR, payload: res})
        }
    } catch (e) {
        yield put({type: actionTypes.GET_ASSESSMENTQUESTIONNAIRE_DEGREE_ERROR, payload: e})
    }
    yield put({type: actionTypes.END_REQUEST})
}


