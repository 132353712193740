import history from "@/utils/history";
import { takeEvery, call, put, select } from 'redux-saga/effects'
import actionTypes from "@/config/actionTypes";
import { request } from "@/request";
import variables from "@/config/variables";
import localOrSessionStore from "@/utils/localOrSessionStore";
import {API_BASE_URL} from "@/config/serverApiConfig";

export default function* watcherAuthentication() {
    yield takeEvery(actionTypes.LOGIN_USER, loginUser)

    yield takeEvery(actionTypes.LOGIN_USERSSO, loginUserSSO)
    yield takeEvery(actionTypes.LOGOUT, logoutUser)
    yield takeEvery(actionTypes.FORGOTPASSWORD, forgotPassword)
    yield takeEvery(actionTypes.RESETPASSWORD, resetPassword)
}


function* loginUserSSO({ payload }) {

    const storage = localOrSessionStore({ key: 'f' });


    yield put({ type: actionTypes.START_REQUEST })

    try {
        let apiEndpoint = `/auth/sso?timestamp=${new Date().getTime()}`

        const res = yield call(
            () =>
                new Promise((resolve, reject) => {
                    request.post(apiEndpoint, payload).then((response) => {
                        resolve(response)
                    })
                }),
        )

        if (res.success && res.result) {
            console.log("MIGUEL!!!!!!")
            storage.setItem(variables.AUTH, 'true')
            storage.setItem(variables.COMPLET, '0')
            storage.setItem(variables.TOKEN, res.result.token)
            storage.setItem(variables.TOKENREFRESH, res.result.refreshToken)
            storage.setItem(variables.USER, JSON.stringify(res.result.user))

            window.location.href="/"
            // yield put({ type: actionTypes.LOGIN_DATA, payload: res });
        } else {
            yield put({ type: actionTypes.LOGIN_ERROR, payload: res })
        }
    } catch (e) {
        yield put({ type: actionTypes.LOGIN_ERROR, payload: e })
    }
    yield put({ type: actionTypes.END_REQUEST })
}

function* forgotPassword({ payload }) {
    yield put({ type: actionTypes.START_REQUEST })

    try {
        let apiEndpoint = '/auth/reset'
        const res = yield call(
            () =>
                new Promise((resolve, reject) => {
                    request.post(apiEndpoint, payload).then((response) => {
                        resolve(response)
                    })
                }),
        )
        if (res.status === 200) {
            yield put({ type: actionTypes.FORGOTPASSWORD_DATA, payload: res.data })
        } else {
            yield put({ type: actionTypes.FORGOTPASSWORD_ERROR, payload: res.data })
        }
    } catch (e) {
        yield put({ type: actionTypes.FORGOTPASSWORD_ERROR, payload: e })
    }
    yield put({ type: actionTypes.END_REQUEST })
}

function* resetPassword({ payload, token }) {
    yield put({ type: actionTypes.START_REQUEST })

    try {
        let apiEndpoint = '/auth/change/' + token
        const res = yield call(
            () =>
                new Promise((resolve, reject) => {
                    request.post(apiEndpoint, payload).then((response) => {
                        resolve(response)
                    })
                }),
        )
        if (res.status === 200) {
            yield put({ type: actionTypes.RESETPASSWORD_DATA, payload: res.data })
        } else {
            yield put({ type: actionTypes.RESETPASSWORD_ERROR, payload: res.data })
        }
    } catch (e) {
        yield put({ type: actionTypes.RESETPASSWORD_ERROR, payload: e })
    }
    yield put({ type: actionTypes.END_REQUEST })
}

function* loginUser({ payload }) {
    if (payload.remember) {
        localStorage.setItem(variables.REMEMBER, "t");
    } else {
        localStorage.setItem(variables.REMEMBER, "f");
    }

    const storage = localOrSessionStore({ key: variables.REMEMBER });


    yield put({ type: actionTypes.START_REQUEST })

    try {
        let apiEndpoint = `/auth/login?timestamp=${new Date().getTime()}`
        
        const res = yield call(
            () =>
                new Promise((resolve, reject) => {
                    request.post(apiEndpoint, payload).then((response) => {
                        resolve(response)
                    })
                }),
        )
        
        if (res.success && res.result) {
            storage.setItem(variables.AUTH, 'true')
            storage.setItem(variables.COMPLET, '0')
            storage.setItem(variables.TOKEN, res.result.token)
            storage.setItem(variables.TOKENREFRESH, res.result.refreshToken)
            storage.setItem(variables.USER, JSON.stringify(res.result.user))
            
            window.location.href="/"
            // yield put({ type: actionTypes.LOGIN_DATA, payload: res });
        } else {
            yield put({ type: actionTypes.LOGIN_ERROR, payload: res })
        }
    } catch (e) {
        yield put({ type: actionTypes.LOGIN_ERROR, payload: e })
    }
    yield put({ type: actionTypes.END_REQUEST })
}

function* logoutUser() {

    const storage = localOrSessionStore({ key: variables.REMEMBER });
    const user = JSON.parse(storage.getItem(variables.USER));

    yield put({ type: actionTypes.START_REQUEST })
    storage.setItem(variables.AUTH, 'false')
    storage.setItem(variables.COMPLET, '0')
    storage.removeItem(variables.TOKEN)
    storage.removeItem(variables.TOKENREFRESH)
    storage.removeItem(variables.USER)
    yield put({ type: actionTypes.END_REQUEST })
    document.location.href=API_BASE_URL + "/login/slo?token=" + user.identifier;

    //window.location.href="/"
}
