import history from "@/utils/history";
import { takeEvery, call, put, select } from 'redux-saga/effects'
import actionTypes from "@/config/actionTypes";
import { request } from "@/request";
import localOrSessionStore from "@/utils/localOrSessionStore";

export default function* watcherSteps() {
   
  yield takeEvery(actionTypes.GET_USERSTEPS, getSteps)
    yield takeEvery(actionTypes.SAVE_STEPUSER, saveStepUser)
}

function* saveStepUser({payload}) {
    yield put({ type: actionTypes.START_REQUEST })

    try {
        let apiEndpoint = `/user/step`
        const res = yield call(
            () =>
                new Promise((resolve, reject) => {
                    request.post(apiEndpoint, {step: payload.step}).then((response) => {
                        resolve(response)
                    })
                }),
        )

        if (res.success && res.result) {
            yield put({ type: actionTypes.SAVE_STEPUSER_DATA, payload: res.result })
        } else {
            yield put({ type: actionTypes.SAVE_STEPUSER_ERROR, payload: res })
        }
        yield put({ type: actionTypes.SAVE_STEPUSER_CLEAR, payload: res })
    } catch (e) {
        yield put({ type: actionTypes.SAVE_STEPUSER_ERROR, payload: e })
        yield put({ type: actionTypes.SAVE_STEPUSER_CLEAR, payload: "" })
    }
    yield put({ type: actionTypes.END_REQUEST })
}


function* getSteps({payload}) {
    yield put({ type: actionTypes.START_REQUEST })
  
    try {
      let apiEndpoint = `/user/steps`
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            request.get(apiEndpoint, null).then((response) => {
              resolve(response)
            })
          }),
      )
      
      if (res.success && res.result) {
        yield put({ type: actionTypes.GET_USERSTEPS_DATA, payload: res.result })
      } else {
        yield put({ type: actionTypes.GET_USERSTEPS_ERROR, payload: res })
      }
    } catch (e) {
      yield put({ type: actionTypes.GET_USERSTEPS_ERROR, payload: e })
    }
    yield put({ type: actionTypes.END_REQUEST })
  }
  
